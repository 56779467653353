import {observer, useLocalObservable} from 'mobx-react-lite';
import {Bet, BetInfo} from 'models/bet';
import {BetStatus, BetTypes} from 'models/enums/Bet.enum';
import {FunctionComponent, useEffect, useState} from 'react';

import useL10n from 'l10n/useL10n';

import './bet.scss';
import Button from 'components/hoc/Button';
import classNames from 'classnames';
import modalServices from 'store/modalServices';
import SocketIoServices from 'services/SocketIoServices';
import roomServices from 'store/roomServices';
import appService from 'store/appService';
import useAnalytics from 'hooks/useAnalytics';
import OauthService from 'services/api/OauthService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {getLocaleDateMessage} from 'utils/message';

interface IBetItem {
	bet: Bet;
	isShareBetModal?: boolean;
	myMessage?: boolean | null;
	userId?: number;
}
const BetItem: FunctionComponent<IBetItem> = function BetItem({
	bet,
	isShareBetModal,
	myMessage,
	userId,
}) {
	const {modals, chatUsersScreen} = useL10n();
	const {language} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomServices);
	const {toggleShareBetModal} = useLocalObservable(() => modalServices);

	const [betPositions, setBetPositions] = useState<BetInfo[]>([]);
	const [isShowMoreBtnVisible, setIsShowMoreBtnVisible] = useState(false);
	const [isOpened, setIsOpened] = useState(false);
	const {sendAnalytics} = useAnalytics();

	const isVisibleShareBetBtnCopy =
		!isShareBetModal && !myMessage && bet.status !== BetStatus.WIN && bet.status !== BetStatus.LOSE;

	const betStatus = [
		{
			text: modals.shareBet.status.null,
			type: null,
		},
		{
			text: modals.shareBet.status.win,
			type: BetStatus.WIN,
		},
		{
			text: modals.shareBet.status.lose,
			type: BetStatus.LOSE,
		},
	];

	const betItemClasses = classNames('bet', {
		'share-bet__item': isShareBetModal,
		'bet--multi': bet.betType === BetTypes.EXPRESS,
		'bet--single': bet.betType === BetTypes.SINGLE,
		'bet--win': bet.status === BetStatus.WIN,
		'bet--lose': bet.status === BetStatus.LOSE,
		'bet--null': bet.status === null,
	});

	const shareClickHandler = () => {
		sendAnalytics('Chat_SharingBet_Send', {
			bet,
			event_id: bet.positions.map(el => el.eventId).toString(),
		});

		roomId &&
			SocketIoServices.emitShareBet({
				externalRoomId: roomId,
				bet,
			});
		toggleShareBetModal(false);
	};

	const showMoreClickHandler = () => {
		bet.positions && setBetPositions(bet.positions);
		setIsShowMoreBtnVisible(false);
		setIsOpened(true);
	};

	const getDecryptedUserId = async () => {
		if (userId) {
			const decryptResponse = await OauthService.getDecryptedUserId(userId);
			if (decryptResponse.status === ResponseStatus.SUCCESS) {
				const {result, decrypted} = decryptResponse.data;
				sendAnalytics('Chat_SharingBet_Repeat', {
					profile_user_id: decrypted,
					bet,
					event_id: bet.positions.map(el => el.eventId).toString(),
				});
			}
		}
	};

	const copyBetClickHandler = () => {
		const betParams = bet.positions.map(el => {
			const {eventId, eventTitle, outcomeId} = el;
			return {eventId, eventTitle, outcomeId};
		});

		const msg = {
			type: 'betRepeat',
			body: {action: 'share', data: {betParams}},
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
		} else window.parent.postMessage(msg, '*');

		getDecryptedUserId();
	};

	const renderBetDate = (date: string) => {
		return (
			<div className='bet__date'>
				{getLocaleDateMessage(date, language, chatUsersScreen.days, true)}
			</div>
		);
	};

	useEffect(() => {
		if (bet.positions) {
			if (bet.positions.length > 3 && !isOpened) {
				setBetPositions(bet.positions.filter((el, index) => index < 4));
				!isShowMoreBtnVisible && setIsShowMoreBtnVisible(true);
				return;
			}
			setBetPositions(bet.positions);
		}
	}, [bet]);

	const renderBetInfo = (item: BetInfo, key: number) => (
		<div className='bet__event' key={key}>
			<div className='bet__event-outcome'>
				<p className='bet__event-outcome-title'>
					{item.outcomeGroupTitle} {item.outcomeTitle} {item.additionalValue} ({item.blockTitle})
				</p>
				<div className='bet__event-outcome-factor'>{item.factorValue}</div>
			</div>
			<p className='bet__event-title'>{item.eventTitle} </p>
		</div>
	);

	return (
		<div className={betItemClasses}>
			<div className='bet__heading'>
				<div className='bet__heading-info'>
					<p className='bet__status'>{betStatus.find(el => el.type === bet.status)?.text}</p>
					<div className='bet__title'>
						{bet.betType === BetTypes.EXPRESS && modals.shareBet.express}
						{bet.betType === BetTypes.SINGLE && modals.shareBet.single}
						{bet.betType === BetTypes.EXPRESS && (
							<span>
								. {bet.positions?.length} {modals.shareBet.bet}
							</span>
						)}
					</div>
				</div>
				<div className='bet__heading-right'>
					{renderBetDate(bet.orderDate)}
					{bet.betType === BetTypes.EXPRESS && (
						<div className='bet__total-factor'>
							{modals.shareBet.totalFactor}: <b>{bet.totalFactor}</b>
						</div>
					)}
				</div>
			</div>

			<div className='bet__content'>
				<div className='bet__events'>
					{betPositions.map(renderBetInfo)}
					{isShowMoreBtnVisible && !isOpened && (
						<Button className='bet__events-btn' onClick={showMoreClickHandler}>
							{modals.shareBet.showMore} {bet.positions.length - 3}
						</Button>
					)}
				</div>

				{(isShareBetModal || isVisibleShareBetBtnCopy) && (
					<div className='bet__bottom'>
						{isShareBetModal && (
							<Button className='btn btn--sm' onClick={shareClickHandler}>
								{modals.shareBet.btns.share}
							</Button>
						)}
						{isVisibleShareBetBtnCopy && (
							<Button className='btn btn--sm' onClick={copyBetClickHandler}>
								{modals.shareBet.btns.copy}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default observer(BetItem);
